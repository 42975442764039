export const menu = [
    {
      name: 'Evaluation',
      page: 'module-evaluation',
      disabled: false,
      hidden: false,
    },
    {
      name: 'Key Terms',
      page: 'module-key-terms',
      disabled: true,
      hidden: false,
    },
    {
      name: 'Educational Content',
      page: 'module-education',
      disabled: true,
      hidden: false,
    },
    {
      name: 'Flashcards',
      page: 'module-flashcards',
      disabled: true,
      hidden: false,
    },
    {
      name: 'Essay Questions',
      page: 'module-essay',
      disabled: true,
      hidden: false,
    }, 
    {
      name: 'AI Simulation',
      page: 'module-ai-simulation',
      disabled: true,
      hidden: true,
    },
    {
      name: 'Quiz',
      page: 'module-quiz',
      disabled: true,
      hidden: false,
    },
    {
      name: 'Assessment Report',
      page: 'module-report',
      disabled: true,
      hidden: false,
    }
];