import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
   getUserCourses, 
   saveCourse, 
   removeEducationalContent, 
   removeSimulationId,
   removeSimulation,
   removeEducationVideoApi,
   removeCourseByIdApi,
   uploadImageApi  
  } from '../../api/coursesApi';
import { RootState } from '../store';
import { menu } from '../../data/modules';
 
type QuizAnswer = {
  type: "right_options" | "true_false" | "fill_blank" | "matching";
  content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;
}[]

type Evaluation = string[];

type QuizDataAnswer = {
  quiz: QuizAnswer | null;
  evaluate: Evaluation | null;
}

type Quiz = {
      type: "right_options" | "true_false" | "fill_blank" | "matching";
      content: Assessment_RightOptions | Assessment_TrueFalse | Assessment_FillBlank | Assessment_Matching[] | any;
  }[] 


type Assessment_RightOptions = {
  question: string;
  options: string[];
  answer: number[];
  userAnswer: number[];
}

type Assessment_TrueFalse = {
  question: string;
  answer: boolean;
  userAnswer: boolean;
}

type Assessment_FillBlank = {
  question: string;
  options: string[];
  answer: number;
  userAnswer: number;
}

type Assessment_Matching = {
  statement: string;
  match: string;
  userAnswer: string;
}[]
export interface FlashCards {
  question: string;
  answer: string;
}
export interface KeyTerms {
  title: string;
  description: string;
}

interface AssessmentReport {
  quiz: string;
  statements_correct: string;
  strong: string[];
  improve: string[];
}

export interface EducationContent {
  id?: number;
  agenda?: string[];
  sections?: Section[];
  summary?: string;
  videoURL?: string | null | undefined;
  headerImage?: string | null;
}

interface Section {
  title: string;
  description: string;
  sub_sections: Block[];
}

interface Block {
  title: string;
  description: string;
  image: string;
}

interface EssayData{
  questions: string[];
  answers?: {
      question: string;
      answer: string;
  }[];
  result?: string;
}

interface AiSimulationRoom {
  mentoring: {
    type: string;
    id: number;
    created_at: string;
    audioURL?: string;
  },
  story: {
    type: string;
    id: number;
    created_at: string;
  },
  student: {
    type: string;
    id: number;
    created_at: string;
  }
}

interface MenuItem {
  name: string;
  page: string;
  disabled: boolean;
  hidden: boolean;
}
type EvaluationAnswers = Record<number, string>;

export interface Course {
  id?: number;
  title: string;
  created_at?: string;
  sections: string[];
  steps: Step[];
  keyTerms?: KeyTerms[]; // Optional property for key terms
  quiz?: Quiz; // Optional property for quiz
  evaluationAnswers?: EvaluationAnswers; // Optional property for evaluation 
  evaluation?: string[]; // Optional property for evaluation
  flashCards?: FlashCards[]; // Define a suitable type if possible
  essay?: EssayData; // Define a suitable type if possible
  educationalContent?: EducationContent; // Define a suitable type if possible
  aiSumulationRoom?: AiSimulationRoom;
  assessmentReport?: AssessmentReport; // Define a suitable type if possible
  summarize?: string; // Define a suitable type if possible
  answers?: QuizDataAnswer; // Define a suitable type if possible
  indexKey?: string; // Define a suitable type if possible
  educationVideo?: string | null | undefined;
  menu?: MenuItem[];
  alreadySaved: boolean;
  data?: string;
  
}
interface FetchCoursesParam {
  token: string | undefined;
  page: number;
}


export interface Step {
  type: string;
  [key: string]: any;  
}


export type CurrentPageType = 
  'module-general' |
  'module-checkpoints' |
  'module-view' |
  'module-evaluation' |
  'module-key-terms' |
  'module-flashcards' |
  'module-education' |
  'module-essay' |
  'module-ai-sim' |
  'module-quiz'  |
  'module-report' |
  'module-ai-simulation' |
  'module-quiz-edit' |
  'module-thanks' ;

  export interface CoursesState {
    courses: Course[];
    activeCourse: Course | null;
    currentPage: CurrentPageType;
    currentTab: string;
    loading: boolean;
    educationLoading: boolean;
    loadingPage: boolean;
    reloadReport: boolean;
    error: string;
    courseAnswers: QuizDataAnswer;  
  }

  const initialState: CoursesState = {
    courses: [],
    currentPage: 'module-general',
    currentTab: 'main',
    activeCourse: null,
    loading: false,
    educationLoading: false,
    loadingPage: false,
    reloadReport: false,
    courseAnswers: {
      quiz:  null,
      evaluate:  null
    },
    error: ''
 
  };


  function prepareCourse(rawCourse: any): Course {
    const parsedData = JSON.parse(rawCourse.data); 
  
    return {
      id: rawCourse.id,
      title: parsedData.title,
      created_at: rawCourse.created_at,
      sections: parsedData.sections,
      steps: parsedData.steps,
      keyTerms: parsedData.keyTerms,
      quiz: parsedData.quiz,
      essay: parsedData.essay,
      evaluation: parsedData.evaluation,
      evaluationAnswers: parsedData.evaluationAnswers,
      educationalContent: parsedData.educationalContent,
      flashCards: parsedData.flashCards,
      summarize: rawCourse.summarize,
      answers: parsedData.answers,
      indexKey: parsedData.indexKey,
      aiSumulationRoom: parsedData.aiSumulationRoom,
      educationVideo: processVideoURL(parsedData),
      menu: processMenu(parsedData),
      assessmentReport: parsedData.assessmentReport,
      alreadySaved: true
    };
  }
  function processVideoURL(data : any ) {
    if(data?.educationalContent && data?.educationalContent?.videoURL) {
      return data?.educationalContent?.videoURL;
    } 
    if(data?.educationVideo) {
      return data?.educationVideo;
    }
    if(data?.educationalContent?.videoURL  === null) { 
      return null;
    }
    return ''
     
  }

  function processMenu(course: Course): MenuItem[] {
    if(course.menu) {
      // const newMenu = course.menu.map((item) => {
      //   if(item.name === 'AI Simulation Room') {
      //     return {
      //       ...item,
      //       name: 'AI Simulation'
      //     }
      //   }
      //   return item;
      // });
      // return  newMenu;
       //TODO - Enable this module after the implementation of the AI Simulation Room new version
       const newMenu = course.menu.map((item) => {
        if(item.page === 'module-ai-simulation') {
          return {
            ...item,
            hidden: true
          }
        }
        return item;
      });
      // return course.menu;
      return newMenu;
    } else{
      return menu;
    }
  }

  export const fetchCourses = createAsyncThunk(
    'courses/fetchCourses',
    async ({token, page}: FetchCoursesParam, thunkAPI) => {
      try {
        const response = await getUserCourses(token, page);  // make sure getUserCourses can handle the page parameter
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    }
  );
  

  export const saveCourses = createAsyncThunk(
    'courses/saveCourses', // Be sure to provide a unique action string
    async (token: string | undefined, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        let activeCourse = state.courses.activeCourse;
        const courseAnswers = state.courses.courseAnswers;
  
        if (!activeCourse) {
          throw new Error("No active course available for saving");
        }
        // Ensure that we don't mutate the original state directly
        const courseToSave = {
          ...activeCourse,
          answers: courseAnswers
        };
        
        const response = await saveCourse(token, courseToSave);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    }
  );

  export const removeEducation = createAsyncThunk(
    'courses/removeEducationalContent', // Be sure to provide a unique action string
    async (token: string | undefined, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        let activeCourse = state.courses?.activeCourse;
  
        if (!activeCourse) {
          throw new Error("No active course available for saving");
        }
        
        const response = await removeEducationalContent(token, activeCourse);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    });

  export const removeSimulationAll = createAsyncThunk(
    'courses/removeSimulationAll', // Be sure to provide a unique action string
    async (token: string | undefined, thunkAPI) => {
      try {
        const response = await removeSimulation(token);

        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    });

  export const removeSimulationById = createAsyncThunk(
    'courses/removeSimulationById', // Be sure to provide a unique action string
    async ({token, id} : {token: string | undefined, id: number | undefined}, thunkAPI) => {
      try {           
        const response = await removeSimulationId(token, id);
        
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    });

    export const removeEducationVideo = createAsyncThunk(
      'courses/removeEducationVideo',
      async ({ educationalContentId, token }: { educationalContentId: number; token: string | undefined }, thunkAPI) => {
        try {
          const response = await removeEducationVideoApi(educationalContentId, token);
          return response.data;
        } catch (error) {
          return thunkAPI.rejectWithValue("Failed to remove education video");
        }
      }
    );
    
    export const uploadImageThunk = createAsyncThunk(
      'courses/uploadImage',
      async ({ formData, token }: { formData: FormData; token: string | undefined }, thunkAPI) => {
        try {
          const response = await uploadImageApi(formData, token);
          return response.data;
        } catch (error) {
          return thunkAPI.rejectWithValue("Failed to upload image");
        }
      }
    );


  export const removeCourseById = createAsyncThunk(
    'courses/removeCourseById', // Be sure to provide a unique action string
    async ({token, id} : {token: string | undefined, id: number | undefined}, thunkAPI) => {
      try {           
        const response = await removeCourseByIdApi(token, id);
        
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue([]);
      }
    });
    
  

  
  export const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
      setCurrentPage: (state, action: PayloadAction<CurrentPageType>) => {
        state.currentPage = action.payload;
      },
      setActiveCourse: (state, action: PayloadAction<Course>) => {
        const course = prepareCourse(action.payload)
        state.activeCourse = {
          ...action.payload,
          ...course
        };
        if(course.answers) {
          state.courseAnswers = course.answers;
        }
        
      },
      setActiveCourseNew: (state, action: PayloadAction<Course>) => {
        state.activeCourse = {
          ...action.payload, 
          educationVideo: processVideoURL(action.payload),
          // educationVideo:
          //  action.payload.educationVideo ||
          //   action.payload?.educationalContent?.videoURL || 
          //   action.payload?.educationalContent?.videoURL  === null ? null : '',
             
           menu: processMenu(action.payload)
        };
      },
      setEducationalContent: (state, action: PayloadAction<EducationContent>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            educationalContent: action.payload
          };
        }
      },
      setQuiz: (state, action: PayloadAction<Quiz>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            quiz: action.payload
          };
        }
      },
      setMenuState: (state, action: PayloadAction<MenuItem[]>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            menu: action.payload
          };
        }
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.loading = action.payload;
      },
      setEducationLoading : (state, action: PayloadAction<boolean>) => {
        state.educationLoading = action.payload;
      },
      setLoadingPage: (state, action: PayloadAction<boolean>) => {
        state.loadingPage = action.payload;
      },
      setCurrentTab: (state, action: PayloadAction<string>) => {
        state.currentTab = action.payload;
      },
      setSetEducationVideo: (state, action: PayloadAction<string | null>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            educationVideo: action.payload
          };
        }
      },
      clearEducationVideo: (state, action: PayloadAction<string | null>) => {
        if(state.activeCourse) { 
          state.activeCourse.educationalContent = {
            ...state.activeCourse.educationalContent,
            videoURL: null
          };
        }
      },
      updateAssessmentReport: (state, action: PayloadAction<AssessmentReport>) => {
        if(state.activeCourse) { 
          state.activeCourse = {
            ...state.activeCourse,
            assessmentReport: {
              ...state.activeCourse.assessmentReport,
              ...action.payload
            }
          };
        }
      },
      clearData: (state) => {
        state.activeCourse = null;
        state.courseAnswers = {
          quiz: null,
          evaluate: null
        };
      },
      updateEssayAnswers: (state, action: PayloadAction<Partial<EssayData>>) => {
        if (state.activeCourse && state.activeCourse.essay) {
          console.log(action.payload.answers);
          state.activeCourse.essay.answers = action.payload.answers || [];
          if(action.payload.result){
            state.activeCourse.essay.result = action.payload.result;
          }
        }
      },    
      updateEvaluationAnswers: (state, action: PayloadAction<EvaluationAnswers>) => {
        if (state.activeCourse) {
          state.activeCourse.evaluationAnswers = action.payload;
        }
      }, 
      updateMentoringURL: (state, action: PayloadAction<string>) => {
        if (state.activeCourse && state.activeCourse.aiSumulationRoom) {
          state.activeCourse.aiSumulationRoom = {
            ...state.activeCourse.aiSumulationRoom,
            mentoring: {
              ...state.activeCourse.aiSumulationRoom?.mentoring,
              audioURL: action.payload
            }
          };
        }
      },
      updateCourseAnswers: (state, action: PayloadAction<Partial<QuizDataAnswer>>) => {
        if (action.payload.quiz) {
          state.courseAnswers = {
            ...state.courseAnswers,
            quiz: [...action.payload.quiz ],
          };
        }
      
        if (action.payload.evaluate) {
          state.courseAnswers = {
            ...state.courseAnswers,
            evaluate: [...action.payload.evaluate],
          };
        }
      },
      setReloadReport: (state, action: PayloadAction<boolean>) => {
        state.reloadReport = action.payload;
      }
      
      
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCourses.fulfilled, (state, action) => {
          const existingCourses = current(state.courses); 
          const combinedCourses = [...existingCourses, ...action.payload[0]];
          const uniqueCourses = combinedCourses.reduce((acc: Course[], course: Course) => {
            const existingCourseIndex = acc.findIndex((c) => c.id === course.id);
            if (existingCourseIndex !== -1) {
              acc[existingCourseIndex] = course; // Replace the old course with the new one
            } else {
              acc.push(course);
            }
            return acc;
          }, []);
          // sort by id from bigger to smaller
          state.courses = uniqueCourses.sort((a: Course, b: Course) => (b.id || 0) - (a.id || 0));
          state.loadingPage = false;
          
        })
        .addCase(fetchCourses.rejected, (state) => {
          state.courses = [];
          state.loadingPage = false;
        })
        .addCase(removeEducationVideo.pending, (state) => {
          state.educationLoading = true;
        })
        .addCase(removeEducationVideo.fulfilled, (state) => {
          state.educationLoading = false;
          if(state.activeCourse) { 
            state.activeCourse = {
              ...state.activeCourse,
              educationVideo: null
            } 
          } 
        }) 
        .addCase(removeEducationVideo.rejected, (state) => {
          state.educationLoading = false;
        })

    }    
  });
  
  export const { 
    setCurrentPage,
    setCurrentTab,
    setQuiz,
    setActiveCourse,
    setActiveCourseNew,
    setMenuState,
    setEducationalContent,
    setLoading, 
    setEducationLoading,
    setSetEducationVideo, 
    setLoadingPage,  
    updateCourseAnswers,
    updateEvaluationAnswers, 
    updateAssessmentReport,
    updateEssayAnswers,
    updateMentoringURL,
    setReloadReport,
    clearData
  } = coursesSlice.actions;
 
  export default coursesSlice.reducer;